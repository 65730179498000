import { render, staticRenderFns } from "./alreay.vue?vue&type=template&id=cd9877f2&scoped=true"
import script from "./alreay.vue?vue&type=script&lang=js"
export * from "./alreay.vue?vue&type=script&lang=js"
import style0 from "./alreay.vue?vue&type=style&index=0&id=cd9877f2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd9877f2",
  null
  
)

export default component.exports